import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Slider from 'react-slick';
import { getProjectsPage, getProjectsPosts } from '../../../util/api.service';
import { slickDot } from '../../../page-demo/script';
import { getImageUrl } from '../../../util/util.service';
import { FormattedDate } from 'react-intl';
import Markdown from 'markdown-to-jsx/dist/index';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../common/footer/footer.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import Skeleton from 'react-loading-skeleton';
import Header from '../../common/header/header.component';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';

const Projects = () => {
  const [projects, setProjects] = useState(null);
  const [projectsPosts, setProjectsPosts] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getProjectsPage(history).then(({ attributes }) => setProjects(attributes));
    getProjectsPosts().then((projectsPosts) => {
      // Sort projectsPosts by date in descending order
      const sortedProjectsPosts = projectsPosts
        .map(({ attributes }) => attributes)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setProjectsPosts(sortedProjectsPosts);
    });
  }, []);

  return (
    <>
      <PageHelmet pageTitle={projects?.title}
                  pageDescription={projects?.subTitle}/>
      <Header/>
      <Breadcrumb title={projects?.title}
                  image={projects?.coverImage}
                  subTitle={projects?.subTitle}
      />

      {/* Start Projects Area */}
      <div class="rn-blog-area ptb--120 bg_color--1">
        <div class="container">
          <div class="row">
            {projectsPosts?.map((projectPost, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                <div className="blog blog-style--1">
                  <div className='thumbnail'>
                    <Link to={'/projects/' + projectPost?.slug}>
                      <img src="/assets/images/press/pressrelease-default-bg.jpg" alt="Projects" />
                    </Link>
                  </div>

                  <div className="content">
                    <div className="inner">
                      <p className='text-white'><FormattedDate value={projectPost.createdAt}/>
                      </p>
                      <h4 className='title'><Link
                        to={'/projects/' + projectPost?.slug}>{projectPost?.title}</Link>
                      </h4>
                      <div className="blog-btn">
                        <Link to={'/projects/' + projectPost?.slug}>
                          <a className="rn-btn">Read More</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End projectsPosts Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default Projects;