import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx/dist/index';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { getProjectsPosts, handleError } from '../../../util/api.service';
import Skeleton from 'react-loading-skeleton';
import { FormattedDate } from 'react-intl';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import Footer from '../../common/footer/footer.component';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';

const ProjectPosts = () => {
  const { slug } = useParams();
  const [projectPosts, setprojectPosts] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getProjectsPosts(slug).
      then((projectPosts) => {
        if (projectPosts?.attributes) {
          setprojectPosts(projectPosts?.attributes);
        } else {
          history.push('/404'); // redirect to 404
        }
      }).catch((err) => handleError(err, history));
  }, [slug]);

  return (
    <>
      <PageHelmet pageTitle={projectPosts?.title} pageDescription={projectPosts?.subTitle}/>
      <Header/>
      <Breadcrumb title="Project Details" subTitle=" " />

      {/* Start Portfolio Details */}
      <div className="rn-blog-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>{projectPosts?.title || <Skeleton count={2}/>}</h2>

                  <p className="subtitle">
                    {projectPosts?.subTitle ? projectPosts?.subTitle : projectPosts?.subTitle === null ? '' : <Skeleton count={2} />}
                  </p>

                  <LazyImage
                    placeholderWidth={500}
                    placeholderHeight={500}
                    image={projectPosts?.coverImage}
                    size="medium"
                    alt="Press Release"
                  />

                  {/* <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>Location</span>
                      <h4>{projectPosts?.location || <Skeleton/>}</h4>
                    </div>

                    <div className="port-view">
                      <span>Start</span>
                      <h4>{projectPosts?.startAt &&
                        <FormattedDate value={projectPosts.startAt}/> ||
                        <Skeleton/>}</h4>
                    </div>

                    <div className="port-view">
                      <span>Type</span>
                      <h4>{projectPosts?.type || <Skeleton/>}</h4>
                    </div>
                  </div> */}

                  {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                    <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                      {SocialShare.map((val, i) => (
                        <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                      ))}
                    </ul>
                  </div>*/}

                  <div className="projectPosts-description">
                    {projectPosts?.description ? <Markdown>{projectPosts.description}</Markdown> :
                      <p><Skeleton width="100%" count={9}/></p>}
                  </div>
                </div>
                {/*<div className="portfolio-thumb-inner">
                  <div className="thumb position-relative mb--30">
                    <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                    <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                  </div>

                  <div className="thumb mb--30">
                    <img src="/assets/images/portfolio/portfolio-big-02.jpg"
                         alt="Portfolio Images"/>
                  </div>

                  <div className="thumb">
                    <img src="/assets/images/portfolio/portfolio-big-01.jpg"
                         alt="Portfolio Images"/>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Related Work */}
      {/*<div className="portfolio-related-work pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="theme-color font--18 fontWeight600">Expanding horizons</span>
                <h2>More opportunities</h2>
              </div>
            </div>
          </div>
          <div className="row mt--10">
             Start Single Portfolio
            <div className="col-lg-6 col-md-6 col-12">
              <div className="related-work text-center mt--30">
                <div className="thumb">
                  <a href="/portfolio-details">
                    <img src="/assets/images/portfolio/related-image-01.jpg"
                         alt="Portfolio-images"/>
                  </a>
                </div>
                <div className="inner">
                  <h4><a href="/portfolio-details">Digital Analysis</a></h4>
                  <span className="category">Technique</span>
                </div>
              </div>
            </div>
             End Single Portfolio
             Start Single Portfolio
            <div className="col-lg-6 col-md-6 col-12">
              <div className="related-work text-center mt--30">
                <div className="thumb">
                  <a href="/portfolio-details">
                    <img src="/assets/images/portfolio/related-image-02.jpg"
                         alt="Portfolio-images"/>
                  </a>
                </div>
                <div className="inner">
                  <h4><a href="/portfolio-details">Plan Management</a></h4>
                  <span className="category">PLANNING</span>
                </div>
              </div>
            </div>
             End Single Portfolio
          </div>
        </div>
      </div>*/}
      {/* End Related Work */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default ProjectPosts;
