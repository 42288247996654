import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';

// this Header detects intersection with the breadcrumb-area automatically and
// switches to dark mode if this area is not visible
const Header = ({ fixed = true, useObserver = true }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const elements = document.querySelectorAll('.has-droupdown > a');
    elements.forEach(element => {
      if (elements.hasOwnProperty(element)) {
        element.onclick = (event) => {
          event.currentTarget.parentElement.querySelector('.submenu').
            classList.
            toggle('active');
          event.currentTarget.classList.toggle('open');
        };
      }
    });
  }, []);

  // manage dark / light header
  // set light mode (light logo, light font, etc.) if intersecting with breadcrumb-area or transparent-header
  useEffect(() => {
    const targets = document.querySelectorAll(
      '.breadcrumb-area, .light-header');
    let observer = null;

    if (useObserver) {
      if (targets?.length) {
        observer = new IntersectionObserver((entries) => {
          // Check if any section is intersecting
          const hasAnyIntersection = entries.some(({isIntersecting}) => isIntersecting);
          setIsDarkMode(!hasAnyIntersection); // Set dark mode if there is an intersection
        }, { root: null, rootMargin: '-50px', threshold: 0 });

        targets.forEach((target) => observer.observe(target));
      } else {
        setIsDarkMode(true); // Set dark mode if no sections found
      }
    }

    // Clean up on destroy
    return () => {
      if (targets && observer) {
        targets.forEach(target => observer.unobserve(target));
      }
    };
  }, []);

  const handleMenuTrigger = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenuTrigger = () => {
    setMenuOpen(false);
  };

  const getLogoTag = () => {
    let file;

    if (isDarkMode) {
      file = 'logo-black-part.svg';
    } else {
      file = 'logo-white-part.svg';
    }

    return <img src={'/assets/images/logo/' + file} alt="Novocycle Logo"/>;
  };

  return (
    <header
      ref={headerRef}
      className={`header-area formobile-menu header--transparent ${isDarkMode
        ? 'color-black'
        : 'default-color'} ${fixed
        ? 'header--fixed'
        : ''}`}>
      <div className={`header-wrapper ${menuOpen ? 'menu-open' : ''}`}
           id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <Link to="/">
              {getLogoTag()}
            </Link>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about-us">About us</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/press">Press</Link></li>
              <li><Link to="/projects">Projects</Link></li>
              <li className="mr-md-0"><Link to="/blog">Blog</Link></li>
              <li className="d-md-none"><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <div className="header-btn">
            <Link to="/contact">
              <a className="rn-btn">
                <span>GET IN TOUCH</span>
              </a>
            </Link>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={handleMenuTrigger}
                  className="menutrigger text-white"><FiMenu/></span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={handleCloseMenuTrigger}
                  className="closeTrigger"><FiX/></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;





